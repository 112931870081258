import { Button } from "../../_ui/Button";
import { Dialog } from "../../_ui/Dialog";
import { useServiceWorker } from "../ServiceWorker";

export const SwUpdateDialog = () => {
  const { updateAssets } = useServiceWorker() || {}

  if (!updateAssets) {
    return null
  }

  return (
    <Dialog title={'Update verfügbar'} open onClose={() => {}} hideCloseButton>
      <div className={'pt-2'}>
        <p className={'text-sm mb-5'}>
          Die Anwendung wurde aktualisiert! Klicke jetzt auf "Jetzt aktualisieren", um die neusten Funktionen und Fehlerbehebungen zu erhalten.
          Anschließend wird die Seite neu geladen und du kannst die App weiter wie gewohnt nutzen.
        </p>
        <Button autoFocus onClick={() => updateAssets()}>Jetzt aktualisieren</Button>
      </div>
    </Dialog>
  )
}
