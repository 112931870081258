import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { Link } from "react-router-dom";
import { classNames } from "../_helpers";

type PropTypes = {
  children: React.ReactNode
}

export const LoginFrame = ({ children }: PropTypes) => {
  return (
    <>
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            {/*<a*/}
            {/*  href={`//${window.location.hostname.replace('app.', '')}:${window.location.port}`}*/}
            {/*  className={'flex items-center gap-4 mb-8 text-gray-900 opacity-40'}*/}
            {/*>*/}
            {/*  <ArrowUturnLeftIcon className={'w-4 h-4'} />*/}
            {/*  Zurück zur Webseite*/}
            {/*</a>*/}
            <Link to={`/dashboard`}>
              <img
                className="h-20 w-auto"
                src="/logo-neu.svg"
                alt="Logo"
              />
            </Link>
            {/*<h2 className="mt-6 text-3xl font-bold tracking-tight text-white opacity-40">Track the REP</h2>*/}
            {/*<p className="mt-2 text-sm text-neutral-400">*/}
            {/*  Nutze deine Octive Zugangsdaten, um dich anzumelden.*/}
            {/*</p>*/}
          </div>

          <div className="mt-8">
            <div className="mt-6">
              {children}
            </div>
          </div>
        </div>

        <div className={'relative flex items-center justify-center gap-12 text-neutral-600 py-8 z-10 text-xs'}>
          <Link to={`/imprint`}>
            Impressum
          </Link>

          <Link to={`/privacy`}>
            Datenschutz
          </Link>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <ProgressiveImage
          src="/victor-freitas-vqDAUejnwKw-unsplash.jpg"
          placeholder="/login-background.jpg"
        >
          {(src, loading) => (
            <img
              src={src}
              alt="Track the rep"
              className={classNames(
                'absolute inset-0 h-full w-full object-cover',
                loading ? 'blur' : ''
              )}
            />
          )}
        </ProgressiveImage>
      </div>
    </>
  )
}
