import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserNav } from "./UserNav";

type PropTypes = {
  noUser: boolean
}

const publicNavigation = [
  {
    name: 'Startseite',
    href: '/'
  },
  {
    name: 'Preise',
    href: '/prices'
  },
]

export const Header = ({ noUser }: PropTypes) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="sticky top-0 z-[80] inset-x-0  bg-neutral-900/80 backdrop-blur">
      <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <Link to={noUser ? '/' : `/dashboard`} className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              alt=""
              src="/logo-neu.svg"
              className="h-10 w-auto"
            />
          </Link>
        </div>
        {noUser ? (
          <>
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-neutral-400"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6"/>
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {publicNavigation.map((item) => (
                <Link key={item.name} to={item.href} className="text-sm/6 font-semibold text-white">
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <Link to={`/dashboard`} className="text-sm/6 font-semibold text-white">
                Anmelden <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </>
        ) : (
          <UserNav />
        )}

      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-[81]"/>
        <DialogPanel
          className="fixed inset-y-0 right-0 z-[81] w-full overflow-y-auto bg-neutral-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <Link to={noUser ? '/' : `/dashboard`} className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="/logo-neu.svg"
                className="h-10 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-neutral-400"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6"/>
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-neutral-500/25">
              <div className="space-y-2 py-6">
                {publicNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-white hover:bg-neutral-800"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <Link
                  to="/dashboard"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-white hover:bg-neutral-800"
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>

    // <header className={'sticky top-0 z-[80]'}>
    //   <div className={'flex items-center justify-between w-full px-6 py-4 bg-neutral-900/80 backdrop-blur border-b border-neutral-700 shaddow text-white'}>
    //     <img src={'/logo-neu.svg'} alt={'Logo'} className={'h-10 text-white'}/>
    //
    //     {!noUser ? (
    //       <Menu>
    //         <MenuButton>
    //           <div
    //             className={'flex items-center justify-center w-8 h-8 rounded-full bg-neutral-200 text-neutral-800 text-sm'}>
    //             {initials}
    //           </div>
    //         </MenuButton>
    //         <MenuItems
    //           transition
    //           anchor="bottom end"
    //           className="w-52 origin-top-right mt-4 z-[81] divide-y divide-neutral-400/30 rounded-lg border border-neutral-400/30 bg-black/60 backdrop-blur p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
    //         >
    //           <MenuItem>
    //             <button
    //               onClick={() => window.location.reload()}
    //               className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
    //             >
    //               Neu laden
    //               <ArrowPathIcon className="w-5 h-5 ml-auto fill-white/30"/>
    //             </button>
    //           </MenuItem>
    //           <MenuItem>
    //             <button
    //               onClick={() => setWodifyImportOpen(true)}
    //               className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
    //             >
    //               Wodify import
    //               <ArrowDownTrayIcon className="w-5 h-5 ml-auto fill-white/30"/>
    //             </button>
    //           </MenuItem>
    //           <MenuItem>
    //             <button
    //               onClick={logout}
    //               className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
    //             >
    //               Abmelden
    //               <ArrowLeftStartOnRectangleIcon className="w-5 h-5 ml-auto fill-white/30"/>
    //             </button>
    //           </MenuItem>
    //         </MenuItems>
    //       </Menu>
    //     ) : (
    //       <div className={'flex gap-6 md:gap-12 tracking-wider text-neutral-100'}>
    //         {publicNavigation.map(item => (
    //           <NavLink to={item.link}>
    //             {item.title}
    //           </NavLink>
    //         ))}
    //       </div>
    //     )}
    //
    //     <ImportWodifyDialog isOpen={wodifyImportOpen} onClose={() => setWodifyImportOpen(false)}/>
    //   </div>
    //
    //   <Breadcrumbs/>
    // </header>
  )
}
