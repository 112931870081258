import React, { Suspense } from 'react';
import { BreadcrumbProvider } from "../../_features/Breadcrumbs";
import { Layout } from "../../_layout";
import { Spinner } from "../../_ui";
import { useMyself } from "../UserContext";

const PricesPage = React.lazy(() => import(/* webpackChunkName: "pricesPage" */'../../_pages/PricesPage')
  .then(({ PricesPage }) => ({default: PricesPage}))
);
const PendingMembershipPage = React.lazy(() => import(/* webpackChunkName: "pendingMembershipPage" */'../../_pages/PendingMembershipPage')
  .then(({ PendingMembershipPage }) => ({default: PendingMembershipPage}))
);

type PropTypes = {
  children: React.ReactNode
  ignoreSubscription?: boolean
}

const loadingSpinner = (
  <div className={'flex justify-center w-full pt-10'}>
    <Spinner size={'w-10 h-10'} />
  </div>
)

const OnboardingContextProvider = ({ children, ignoreSubscription }: PropTypes) => {
  const { hasActiveSubscription, hasBoxMembership, hasPendingBoxMembership, boxOwnership } = useMyself() || {}

  if (!hasActiveSubscription && !hasBoxMembership && hasPendingBoxMembership && !boxOwnership) {
    return (
      <BreadcrumbProvider>
        <Layout hideBottomNav>
          <Suspense fallback={loadingSpinner}>
            <PendingMembershipPage />
          </Suspense>
        </Layout>
      </BreadcrumbProvider>
    )
  }

  if (!hasActiveSubscription && !hasBoxMembership && !ignoreSubscription && !boxOwnership) {
    return (
      <BreadcrumbProvider>
        <Layout hideBottomNav noUser>
          <Suspense fallback={loadingSpinner}>
            <PricesPage />
          </Suspense>
        </Layout>
      </BreadcrumbProvider>
    )
  }

  return (
    <>
      {children}
    </>
  );
}

export default OnboardingContextProvider;
