import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { classNames } from "../_helpers";

type PropTypes = {
  noUser: boolean
}

const navigation = [
  {
    title: 'Übungen',
    link: '/exercises'
  },
  {
    title: 'Workout',
    link: '/workout'
  }
]

export const AppNav = ({ noUser }: PropTypes) => {
  if (noUser) {
    return null
  }

  return (
    <div
      className="fixed bottom-0 left-0 z-50 w-full pb-safe bg-neutral-900/80 backdrop-blur border-t border-neutral-700 shaddow">
      <div className="grid h-full max-w-lg grid-cols-2 mx-auto font-medium">
        {navigation.map(item => (
          <NavLink
            key={item.link}
            to={item.link}
            className={({isActive, isPending}) => classNames(
              "inline-flex flex-col items-center justify-center px-5 py-3 hover:bg-neutral-900/50 group",
            )}
          >
            {({isActive, isPending, isTransitioning}) => (
              <>
                <CalendarDaysIcon className={classNames(
                  'w-5 h-5 mb-2  group-hover:text-sky-400',
                  isActive || isPending ? 'text-sky-400' : 'text-neutral-500'
                )}/>
                <span className={classNames(
                  'text-xs group-hover:text-white/70',
                  isActive || isPending ? 'text-white' : 'text-neutral-500'
                )}>
                  {item.title}
                </span>
              </>
            )}
          </NavLink>
        ))}
      </div>
    </div>
  )
}
