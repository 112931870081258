import React, { Suspense } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import { Outlet } from 'react-router-dom';
import { BreadcrumbProvider } from "./_features/Breadcrumbs";
import { Layout } from './_layout';
import { Spinner } from "./_ui";

const loadingSpinner = (
  <div className={'flex justify-center w-full pt-10'}>
    <Spinner size={'w-10 h-10'} />
  </div>
)

export const AppPublic = () => {
  return (
    <BreadcrumbProvider>
      <Layout noUser>
        <Suspense fallback={loadingSpinner}>
          <Outlet />
        </Suspense>
      </Layout>
    </BreadcrumbProvider>
  );
}
