import React, { createContext, ReactNode, useCallback, useContext, useState } from "react";

// Definiere den Typen für ein Breadcrumb
interface Breadcrumb {
  label: string;
  path: string;
}

// Definiere den Typen für den Context
interface BreadcrumbContextType {
  breadcrumbs: Breadcrumb[];
  updateBreadcrumbs: (newBreadcrumbs: Breadcrumb[]) => void;
}

// Initialisiere den Context mit einem leeren Standardwert
const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

// Custom Hook zum Zugriff auf den Context
export const useBreadcrumb = (): BreadcrumbContextType => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error("useBreadcrumb muss innerhalb eines BreadcrumbProviders verwendet werden");
  }
  return context;
};

// Provider Komponente mit Typen für die Props
interface BreadcrumbProviderProps {
  children: ReactNode;
}

export const BreadcrumbProvider = ({ children }: BreadcrumbProviderProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const updateBreadcrumbs = useCallback((newBreadcrumbs: Breadcrumb[]) => {
    setBreadcrumbs(newBreadcrumbs);
  }, []);

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbs, updateBreadcrumbs }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};
