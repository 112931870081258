import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useClient } from "urql";
import { readBoxOwnershipQuery, readMyselfQuery } from "../../_lib/graphql/queries";

type PropTypes = {
  children: React.ReactNode
}

interface WebSocketContextType {
  hasSocketConnection: boolean
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const useWebSocket = () => useContext(WebSocketContext)

export const Websocket = ({children}: PropTypes) => {
  const initRef = useRef(false)
  const [hasSocketConnection, setHasSocketConnection] = useState(false)
  const client = useClient()

  const handleMessage = useCallback((message: any) => {
    let vars, query = null
    let hasData = null
    switch (message.data.object) {
      case 'SW':
        console.log('SERVICE WORKER UPDATE', message)
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.ready
            .then((registration) => {
              registration.update().then(r => console.log(r));
            })
            .catch((error) => {
              console.error(error.message);
            });
        }
        break;

      // case 'UserGroup':
      //   vars = { input: { groupID: message.data.value } }
      //   query = readOneGroupQuery
      //   hasData = (value: any) => {
      //     const data = value?.readOneGroup || {}
      //     return Object.keys(data).findIndex(key => key !== 'id' && data[key] !== null) >= 0
      //   }
      //   break;
      case 'Customer':
        vars = {}
        query = readBoxOwnershipQuery
        hasData = (value: any) => {
          const data = value?.readBoxOwnership || {}
          return data?.id && data.id === message.data.value
        }
        break;
      case 'User':
        vars = {}
        query = readMyselfQuery
        hasData = (value: any) => {
          const data = value?.readMyself || {}
          return data?.id && data.id === message.data.value
        }
        break;
      default:
    }

    if (query) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const queryData = client.readQuery(query, vars, {requestPolicy: 'cache-only'})?.data

      // console.log(queryData)
      if (hasData && hasData(queryData)) {
        client.query(query, vars).toPromise()
      } else {
        console.log('Ignored because of emtpy query', message)
      }
    }
  }, [client])

  const connect = useCallback(() => {
    console.log('Try to connect')
    const ws = new WebSocket('wss://ws.tracktherep.de/');
    // const ws = new WebSocket('ws://node.rockambeckenrand.docker:8888/');

    ws.addEventListener('open', () => {
      console.log('connected to ws');

      const token = sessionStorage.getItem('socket-id')

      if (!token) {
        ws.send(JSON.stringify({
          'type': 'register'
        }))
      } else {
        ws.send(JSON.stringify({
          'type': 'login',
          'clientID': token
        }))
      }

      setHasSocketConnection(true)
    });

    ws.addEventListener('close', () => {
      console.log('disconnected');
      setHasSocketConnection(false)
      setTimeout(() => connect(), 1000)
    });

    ws.addEventListener("message", event => {
      console.log(event)
      if (event.data instanceof Blob) {
        const reader = new FileReader();

        reader.onload = () => {
          if (typeof reader.result !== "string") {
            return
          }

          const result = JSON.parse(reader.result)

          let stage = 'live'
          if (window.location.host.includes('staging')) {
            stage = 'test'
          } else if (window.location.host.includes('localhost:')) {
            stage = 'dev'
          }

          console.log(window.location.host, 'host')

          if (result?.Stage === stage) {
            console.log('Recived:', result)
            console.log('Own socket id', sessionStorage.getItem('socket-id'))
            if (result.senderID !== sessionStorage.getItem('socket-id')) {
              handleMessage(result)
            } else {
              console.log('Ignore own message')
            }
          } else {
            console.log('Stage ' + result?.Stage + ' ignored')
          }
        };

        reader.readAsText(event.data);
      } else {
        const result = JSON.parse(event.data)

        if (result.socketID) {
          sessionStorage.setItem('socket-id', result.socketID)
        }
      }
    });

    ws.addEventListener('error', error => {
      ws.close();
      // setTimeout(() => connect(), 1000)
    })
  }, [handleMessage])

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true
      connect()
    }
  }, [connect])

  return (
    <WebSocketContext.Provider value={{hasSocketConnection}}>
      {children}
    </WebSocketContext.Provider>
  )
}
