import { graphql } from "../../../gql";


export const readMyselfQuery = graphql(`
  query ReadMyself {
    readMyself {
      id
      firstName
      surname
      boxOwnership {
        id
        title
      }
      hasActiveSubscription
      hasBoxMembership
      hasPendingBoxMembership
    }
  }
`);

export const readExercisesQuery = graphql(`
  query ReadExercises {
    readExercises {
      id
      title
      bestResult
      lastTraining
    }
  }
`);

export const readOneExerciseQuery = graphql(`
  query ReadOneExercise($input: ReadOneExerciseInput!) {
    readOneExercise(input: $input) {
      id
      title
      bestResult
      lastTraining
      exerciseResults {
        id
        created
        repetitions
        result
        notice
      }
    }
  }
`);

export const readOneExerciseResultQuery = graphql(`
  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {
    readOneExerciseResult(input: $input) {
      id
      created
      repetitions
      result
      barWeight
      selectedPlates
      notice
    }
  }
`);

export const readWorkoutsByDateQuery = graphql(`
  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {
    readWorkoutsByDate(input: $input) {
      public {
        id
        title
        date
        customer {
          id
          title
        }
        presets {
          id
          sort
          repetitions
          exercise {
            id
          }
        }
      }
      private {
        id
        title
        date
        presets {
          id
          sort
          repetitions
          exercise {
            id
          }
        }
      }
    }
  }
`);

export const readOneWorkoutQuery = graphql(`
  query ReadOneWorkout($input: ReadOneWorkoutInput!) {
    readOneWorkout(input: $input) {
      id
      title
      date
      presets {
        id
        sort
        repetitions
        exercise {
          id
        }
        results {
          id
          result
          athletID
        }
      }
      teammates {
        id
        name
      }
    }
  }
`);

export const readCustomerQuery = graphql(`
  query ReadCustomer {
    readCustomer {
      id
      title
      description
    }
  }
`);

export const readBoxOwnershipQuery = graphql(`
  query ReadBoxOwnership {
    readBoxOwnership {
      id
      title
      confirmedMembers {
        id
        firstName
        surname
      }
      requestedMembers {
        id
        firstName
        surname
      }
    }
  }
`);

export const readPricesQuery = graphql(`
  query ReadPrices {
    readPrices {
      id
      amount
      period
      title
      description
    }
  }
`);

export const readContentQuery = graphql(`
  query ReadContent($type: String!) {
    readContent(type: $type)
  }
`);

export const readFaqItemsQuery = graphql(`
  query ReadFaqItems {
    readFaqItems {
      id
      title
      content
    }
  }
`);
