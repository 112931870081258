import React from "react";

type PropType = {
  label: string
  name?: string
  type?: string
  autoComplete?: string
  value: string
  onChange: (value: string) => void
  required?: boolean
  disabled?: boolean
}

export const Textfield = ({ label, name, type = 'text', autoComplete, value, onChange, required = false, disabled = false }: PropType) => {
  return (
    <label className={'block w-full'}>
      <span className="block text-sm font-medium text-neutral-300">
        {label}
      </span>
      <div className="mt-1">
        <input
          name={name}
          type={type}
          autoComplete={autoComplete}
          required={required}
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          className="block w-full bg-neutral-800 appearance-none rounded-md border border-neutral-600 text-neutral-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-sky-500 sm:text-sm"
        />
      </div>
    </label>
  )
}
