/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  mutation RegisterMember($input: RegisterMemberInput!) {\n    registerMember(input: $input)\n  }\n": types.RegisterMemberDocument,
    "\n  mutation SetNewPassword($input: SetNewPasswordInput!) {\n    setNewPassword(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n": types.SetNewPasswordDocument,
    "\n  mutation LoginUser($input: LoginUserInput!) {\n    loginUser(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n": types.LoginUserDocument,
    "\n  mutation RefreshToken($refresh_token: String!) {\n    refreshToken(refresh_token: $refresh_token) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n": types.RefreshTokenDocument,
    "\n  mutation LogoutUser {\n    logoutUser\n  }\n": types.LogoutUserDocument,
    "\n  mutation AddResult($input: AddResultInput!) {\n    addResult(input: $input) {\n      id\n      created\n      repetitions\n      result\n      notice\n    }\n  }\n": types.AddResultDocument,
    "\n  mutation AddResultForPreset($input: AddResultForPresetInput!) {\n    addResultForPreset(input: $input) {\n      id\n      results {\n        id\n        result\n        athletID\n      }\n    }\n  }\n": types.AddResultForPresetDocument,
    "\n  mutation AddAthlet($input: AddAthletInput!) {\n    addAthlet(input: $input) {\n      id\n      teammates {\n        id\n        name\n      }\n    }\n  }\n": types.AddAthletDocument,
    "\n  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {\n    createCheckoutSession(input: $input)\n  }\n": types.CreateCheckoutSessionDocument,
    "\n  mutation CreateBillingPortalSession {\n    createBillingPortalSession\n  }\n": types.CreateBillingPortalSessionDocument,
    "\n  mutation UpdateSubscriptionStatus {\n    updateSubscriptionStatus {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n": types.UpdateSubscriptionStatusDocument,
    "\n  mutation JoinBox($input: JoinBoxInput!) {\n    joinBox(input: $input) {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n": types.JoinBoxDocument,
    "\n  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {\n    confirmBoxMembership(input: $input) {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n": types.ConfirmBoxMembershipDocument,
    "\n  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {\n    cancelBoxMembership(input: $input) {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n": types.CancelBoxMembershipDocument,
    "\n  mutation AddWorkout($input: AddWorkoutInput!) {\n    addWorkout(input: $input) {\n      public {\n        id\n        title\n        date\n        customer {\n          id\n          title\n        }\n        presets {\n          id\n          sort\n          repetitions\n        }\n      }\n      private {\n        id\n        title\n        date\n        presets {\n          id\n          repetitions\n        }\n      }\n    }\n  }\n": types.AddWorkoutDocument,
    "\n  mutation DeleteWorkout($input: DeleteWorkoutInput!) {\n    deleteWorkout(input: $input) {\n      public {\n        id\n      }\n      private {\n        id\n      }\n    }\n  }\n": types.DeleteWorkoutDocument,
    "\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input)\n  }\n": types.ResetPasswordDocument,
    "\n  query ReadMyself {\n    readMyself {\n      id\n      firstName\n      surname\n      boxOwnership {\n        id\n        title\n      }\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n": types.ReadMyselfDocument,
    "\n  query ReadExercises {\n    readExercises {\n      id\n      title\n      bestResult\n      lastTraining\n    }\n  }\n": types.ReadExercisesDocument,
    "\n  query ReadOneExercise($input: ReadOneExerciseInput!) {\n    readOneExercise(input: $input) {\n      id\n      title\n      bestResult\n      lastTraining\n      exerciseResults {\n        id\n        created\n        repetitions\n        result\n        notice\n      }\n    }\n  }\n": types.ReadOneExerciseDocument,
    "\n  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {\n    readOneExerciseResult(input: $input) {\n      id\n      created\n      repetitions\n      result\n      barWeight\n      selectedPlates\n      notice\n    }\n  }\n": types.ReadOneExerciseResultDocument,
    "\n  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {\n    readWorkoutsByDate(input: $input) {\n      public {\n        id\n        title\n        date\n        customer {\n          id\n          title\n        }\n        presets {\n          id\n          sort\n          repetitions\n          exercise {\n            id\n          }\n        }\n      }\n      private {\n        id\n        title\n        date\n        presets {\n          id\n          sort\n          repetitions\n          exercise {\n            id\n          }\n        }\n      }\n    }\n  }\n": types.ReadWorkoutsByDateDocument,
    "\n  query ReadOneWorkout($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      date\n      presets {\n        id\n        sort\n        repetitions\n        exercise {\n          id\n        }\n        results {\n          id\n          result\n          athletID\n        }\n      }\n      teammates {\n        id\n        name\n      }\n    }\n  }\n": types.ReadOneWorkoutDocument,
    "\n  query ReadCustomer {\n    readCustomer {\n      id\n      title\n      description\n    }\n  }\n": types.ReadCustomerDocument,
    "\n  query ReadBoxOwnership {\n    readBoxOwnership {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n": types.ReadBoxOwnershipDocument,
    "\n  query ReadPrices {\n    readPrices {\n      id\n      amount\n      period\n      title\n      description\n    }\n  }\n": types.ReadPricesDocument,
    "\n  query ReadContent($type: String!) {\n    readContent(type: $type)\n  }\n": types.ReadContentDocument,
    "\n  query ReadFaqItems {\n    readFaqItems {\n      id\n      title\n      content\n    }\n  }\n": types.ReadFaqItemsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RegisterMember($input: RegisterMemberInput!) {\n    registerMember(input: $input)\n  }\n"): (typeof documents)["\n  mutation RegisterMember($input: RegisterMemberInput!) {\n    registerMember(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetNewPassword($input: SetNewPasswordInput!) {\n    setNewPassword(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"): (typeof documents)["\n  mutation SetNewPassword($input: SetNewPasswordInput!) {\n    setNewPassword(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LoginUser($input: LoginUserInput!) {\n    loginUser(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"): (typeof documents)["\n  mutation LoginUser($input: LoginUserInput!) {\n    loginUser(input: $input) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RefreshToken($refresh_token: String!) {\n    refreshToken(refresh_token: $refresh_token) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"): (typeof documents)["\n  mutation RefreshToken($refresh_token: String!) {\n    refreshToken(refresh_token: $refresh_token) {\n      id\n      access_token\n      refresh_token\n      expiry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LogoutUser {\n    logoutUser\n  }\n"): (typeof documents)["\n  mutation LogoutUser {\n    logoutUser\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddResult($input: AddResultInput!) {\n    addResult(input: $input) {\n      id\n      created\n      repetitions\n      result\n      notice\n    }\n  }\n"): (typeof documents)["\n  mutation AddResult($input: AddResultInput!) {\n    addResult(input: $input) {\n      id\n      created\n      repetitions\n      result\n      notice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddResultForPreset($input: AddResultForPresetInput!) {\n    addResultForPreset(input: $input) {\n      id\n      results {\n        id\n        result\n        athletID\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddResultForPreset($input: AddResultForPresetInput!) {\n    addResultForPreset(input: $input) {\n      id\n      results {\n        id\n        result\n        athletID\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddAthlet($input: AddAthletInput!) {\n    addAthlet(input: $input) {\n      id\n      teammates {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddAthlet($input: AddAthletInput!) {\n    addAthlet(input: $input) {\n      id\n      teammates {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {\n    createCheckoutSession(input: $input)\n  }\n"): (typeof documents)["\n  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {\n    createCheckoutSession(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBillingPortalSession {\n    createBillingPortalSession\n  }\n"): (typeof documents)["\n  mutation CreateBillingPortalSession {\n    createBillingPortalSession\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSubscriptionStatus {\n    updateSubscriptionStatus {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSubscriptionStatus {\n    updateSubscriptionStatus {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation JoinBox($input: JoinBoxInput!) {\n    joinBox(input: $input) {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"): (typeof documents)["\n  mutation JoinBox($input: JoinBoxInput!) {\n    joinBox(input: $input) {\n      id\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {\n    confirmBoxMembership(input: $input) {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {\n    confirmBoxMembership(input: $input) {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {\n    cancelBoxMembership(input: $input) {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {\n    cancelBoxMembership(input: $input) {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddWorkout($input: AddWorkoutInput!) {\n    addWorkout(input: $input) {\n      public {\n        id\n        title\n        date\n        customer {\n          id\n          title\n        }\n        presets {\n          id\n          sort\n          repetitions\n        }\n      }\n      private {\n        id\n        title\n        date\n        presets {\n          id\n          repetitions\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddWorkout($input: AddWorkoutInput!) {\n    addWorkout(input: $input) {\n      public {\n        id\n        title\n        date\n        customer {\n          id\n          title\n        }\n        presets {\n          id\n          sort\n          repetitions\n        }\n      }\n      private {\n        id\n        title\n        date\n        presets {\n          id\n          repetitions\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteWorkout($input: DeleteWorkoutInput!) {\n    deleteWorkout(input: $input) {\n      public {\n        id\n      }\n      private {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteWorkout($input: DeleteWorkoutInput!) {\n    deleteWorkout(input: $input) {\n      public {\n        id\n      }\n      private {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input)\n  }\n"): (typeof documents)["\n  mutation ResetPassword($input: ResetPasswordInput!) {\n    resetPassword(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadMyself {\n    readMyself {\n      id\n      firstName\n      surname\n      boxOwnership {\n        id\n        title\n      }\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"): (typeof documents)["\n  query ReadMyself {\n    readMyself {\n      id\n      firstName\n      surname\n      boxOwnership {\n        id\n        title\n      }\n      hasActiveSubscription\n      hasBoxMembership\n      hasPendingBoxMembership\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadExercises {\n    readExercises {\n      id\n      title\n      bestResult\n      lastTraining\n    }\n  }\n"): (typeof documents)["\n  query ReadExercises {\n    readExercises {\n      id\n      title\n      bestResult\n      lastTraining\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneExercise($input: ReadOneExerciseInput!) {\n    readOneExercise(input: $input) {\n      id\n      title\n      bestResult\n      lastTraining\n      exerciseResults {\n        id\n        created\n        repetitions\n        result\n        notice\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadOneExercise($input: ReadOneExerciseInput!) {\n    readOneExercise(input: $input) {\n      id\n      title\n      bestResult\n      lastTraining\n      exerciseResults {\n        id\n        created\n        repetitions\n        result\n        notice\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {\n    readOneExerciseResult(input: $input) {\n      id\n      created\n      repetitions\n      result\n      barWeight\n      selectedPlates\n      notice\n    }\n  }\n"): (typeof documents)["\n  query ReadOneExerciseResult($input: ReadOneExerciseResultInput!) {\n    readOneExerciseResult(input: $input) {\n      id\n      created\n      repetitions\n      result\n      barWeight\n      selectedPlates\n      notice\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {\n    readWorkoutsByDate(input: $input) {\n      public {\n        id\n        title\n        date\n        customer {\n          id\n          title\n        }\n        presets {\n          id\n          sort\n          repetitions\n          exercise {\n            id\n          }\n        }\n      }\n      private {\n        id\n        title\n        date\n        presets {\n          id\n          sort\n          repetitions\n          exercise {\n            id\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadWorkoutsByDate($input: ReadWorkoutsByDateInput!) {\n    readWorkoutsByDate(input: $input) {\n      public {\n        id\n        title\n        date\n        customer {\n          id\n          title\n        }\n        presets {\n          id\n          sort\n          repetitions\n          exercise {\n            id\n          }\n        }\n      }\n      private {\n        id\n        title\n        date\n        presets {\n          id\n          sort\n          repetitions\n          exercise {\n            id\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadOneWorkout($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      date\n      presets {\n        id\n        sort\n        repetitions\n        exercise {\n          id\n        }\n        results {\n          id\n          result\n          athletID\n        }\n      }\n      teammates {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadOneWorkout($input: ReadOneWorkoutInput!) {\n    readOneWorkout(input: $input) {\n      id\n      title\n      date\n      presets {\n        id\n        sort\n        repetitions\n        exercise {\n          id\n        }\n        results {\n          id\n          result\n          athletID\n        }\n      }\n      teammates {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadCustomer {\n    readCustomer {\n      id\n      title\n      description\n    }\n  }\n"): (typeof documents)["\n  query ReadCustomer {\n    readCustomer {\n      id\n      title\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadBoxOwnership {\n    readBoxOwnership {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReadBoxOwnership {\n    readBoxOwnership {\n      id\n      title\n      confirmedMembers {\n        id\n        firstName\n        surname\n      }\n      requestedMembers {\n        id\n        firstName\n        surname\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadPrices {\n    readPrices {\n      id\n      amount\n      period\n      title\n      description\n    }\n  }\n"): (typeof documents)["\n  query ReadPrices {\n    readPrices {\n      id\n      amount\n      period\n      title\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadContent($type: String!) {\n    readContent(type: $type)\n  }\n"): (typeof documents)["\n  query ReadContent($type: String!) {\n    readContent(type: $type)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ReadFaqItems {\n    readFaqItems {\n      id\n      title\n      content\n    }\n  }\n"): (typeof documents)["\n  query ReadFaqItems {\n    readFaqItems {\n      id\n      title\n      content\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;