import SuperTokensLock from 'browser-tabs-lock'
import { authExchange } from '@urql/exchange-auth'
import { refreshTokenMutation } from '../mutations'
import 'navigator.locks'

let superTokensLock = new SuperTokensLock()

async function initializeAuthState() {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refresh_token');

  return { token, refreshToken };
}

const checkTokenExpiry = () => {
  const currTime = Math.round(new Date().getTime() / 1000)
  const tokenEndOfLife = parseInt(localStorage.getItem('expiredTimestamp'), 10)

  return currTime > tokenEndOfLife;
}

const goRefresh = async (data, utils) => {
  if (await superTokensLock.acquireLock('refresh_token_lock', 5000)) {
    let token, refreshToken;

    if (checkTokenExpiry()) {
      await utils.mutate(refreshTokenMutation, {
        refresh_token: data.refreshToken,
      }).then(result => {
        if (result.data?.refreshToken) {
          token = result.data.refreshToken?.access_token;
          refreshToken = result.data.refreshToken?.refresh_token;

          // save the new tokens in storage for next restart
          localStorage.setItem('token', token);
          localStorage.setItem('refresh_token', refreshToken);

          const time = Math.round(new Date().getTime() / 1000)
          const expiredTimestamp = time + parseInt(result.data.refreshToken?.expiry, 10)
          localStorage.setItem('expiredTimestamp', expiredTimestamp)
        } else {
          token = null
          refreshToken = null
        }
      })
    }

    await superTokensLock.releaseLock('refresh_token_lock');
  }
}

const exchange = authExchange(async utils => {
  let token = localStorage.getItem('token');
  let refreshToken = localStorage.getItem('refresh_token');

  return {
    addAuthToOperation(operation) {
      if (token !== localStorage.getItem('token') || refreshToken !== localStorage.getItem('refresh_token')) {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refresh_token');
      }

      if (!token || !refreshToken) {
        token = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refresh_token');

        if (!token) {
          return operation;
        }
      }
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${token}`,
      });
    },
    didAuthError(error, _operation) {
      return error.graphQLErrors.some(
        e => e.message.includes('Invalid token')
          || e.message.includes('The resource owner or authorization server denied the request')
      )
    },
    async refreshAuth() {
      const data = await initializeAuthState();

      if (data.refreshToken) {
        await goRefresh(data, utils)
      }
    },
    willAuthError: (operation) => {
      const token = localStorage.getItem('token')

      if (!token) {
        return true
      }

      if (
        operation.kind === 'mutation' &&
        // Here we find any mutation definition with the "login" field
        operation.query.definitions.some(definition => {
          return (
            definition.kind === 'OperationDefinition' &&
            definition.selectionSet.selections.some(node => {
              // The field name is just an example, since signup may also be an exception
              return node.kind === 'Field' && ['createToken', 'logout'].includes(node.name.value);
            })
          );
        })
      ) {
        return false;
      } else {
        return checkTokenExpiry()
      }
    },
  };
});

export default exchange
