import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  ArrowLeftStartOnRectangleIcon,
  ArrowPathIcon,
  CurrencyEuroIcon, InformationCircleIcon, ShieldCheckIcon,
  UserGroupIcon
} from "@heroicons/react/24/outline";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import { useMyself } from "../_context/UserContext";
import { ImportWodifyDialog } from "../_features/ImportWodifyDialog";
import { createBillingPortalSessionMutation } from "../_lib/graphql/mutations";

export const UserNav = () => {
  const navigate = useNavigate()
  const [wodifyImportOpen, setWodifyImportOpen] = useState(false)
  const { firstName, surname, hasActiveSubscription, hasBoxMembership, boxOwnership, logout } = useMyself() || {};

  const [state, executeBillingPortalSession] = useMutation(createBillingPortalSessionMutation)

  const initials = useMemo(() => {
    return `${firstName?.slice(0, 1) || ''}${surname?.slice(0, 1) || ''}`
  }, [firstName, surname])

  const handleBillingPortalClick = useCallback(() => {
    executeBillingPortalSession({}).then(res => {
      if (res.data?.createBillingPortalSession) {
        window.location.href = res.data?.createBillingPortalSession || ''
      }
    })
  }, [executeBillingPortalSession])

  return (
    <>
      <Menu>
        <MenuButton>
          <div
            className={'flex items-center justify-center w-8 h-8 rounded-full bg-neutral-200 text-neutral-800 text-sm'}>
            {initials}
          </div>
        </MenuButton>
        <MenuItems
          transition
          anchor="bottom end"
          className="w-52 origin-top-right mt-4 z-[81] divide-y divide-neutral-400/30 rounded-lg border border-neutral-400/30 bg-black/60 backdrop-blur p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <MenuItem>
            <button
              onClick={() => window.location.reload()}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              Neu laden
              <ArrowPathIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          {(hasActiveSubscription || hasBoxMembership) && (
            <>
              <MenuItem>
                <button
                  onClick={() => setWodifyImportOpen(true)}
                  className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
                >
                  Wodify import
                  <ArrowDownTrayIcon className="w-5 h-5 ml-auto fill-white/30"/>
                </button>
              </MenuItem>

              <MenuItem>
                <button
                  onClick={handleBillingPortalClick}
                  className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
                  disabled={state.fetching}
                >
                  Abo verwalten
                  <CurrencyEuroIcon className="w-5 h-5 ml-auto fill-white/30"/>
                </button>
              </MenuItem>
            </>
          )}
          {boxOwnership?.id && (
            <>
              <MenuItem>
                <button
                  onClick={() => navigate(`/box-members`)}
                  className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
                  disabled={state.fetching}
                >
                  Mitglieder verwalten
                  <UserGroupIcon className="w-5 h-5 ml-auto fill-white/30"/>
                </button>
              </MenuItem>
            </>
          )}
          <MenuItem>
            <button
              onClick={() => navigate(`/privacy`)}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
              disabled={state.fetching}
            >
              Datenschutz
              <InformationCircleIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={() => navigate(`/imprint`)}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
              disabled={state.fetching}
            >
              Impressum
              <InformationCircleIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={() => navigate(`/terms-of-service`)}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
              disabled={state.fetching}
            >
              AGB
              <ShieldCheckIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={logout}
              className="group flex w-full items-center gap-2 py-2 px-3 data-[focus]:bg-white/10"
            >
              Abmelden
              <ArrowLeftStartOnRectangleIcon className="w-5 h-5 ml-auto fill-white/30"/>
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>

      <ImportWodifyDialog
        isOpen={wodifyImportOpen}
        onClose={() => setWodifyImportOpen(false)}
      />
    </>
  )
}
