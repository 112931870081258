import React, { Suspense } from 'react';
import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { Outlet } from 'react-router-dom';
import OnboardingContext from "./_context/OnboardingContext";
import UserContext from "./_context/UserContext";
import { BreadcrumbProvider } from "./_features/Breadcrumbs";
import { Layout } from './_layout';
import { Spinner } from "./_ui";

const loadingSpinner = (
  <div className={'flex justify-center w-full pt-10'}>
    <Spinner size={'w-10 h-10'} />
  </div>
)

type PropTypes = {
  hideBottomNav?: boolean
  ignoreSubscription?: boolean
}

export const AppProtected = ({ ignoreSubscription, hideBottomNav = false }: PropTypes) => {
  return (
    <UserContext ignoreSubscription={ignoreSubscription}>
      <SkeletonTheme>
        <OnboardingContext ignoreSubscription={ignoreSubscription}>
          <BreadcrumbProvider>
            <Layout hideBottomNav={hideBottomNav}>
              <Suspense fallback={loadingSpinner}>
                <Outlet />
              </Suspense>
            </Layout>
          </BreadcrumbProvider>
        </OnboardingContext>
      </SkeletonTheme>
    </UserContext>
  );
}
