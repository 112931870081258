import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useCallback, useState } from "react";
// import { uploadCsvMutation } from "../../_lib/graphql/mutations";
import { useNavigate } from "react-router-dom";
// import { useMutation } from "urql";
import { IMPORT_ENDPOINT } from "../../_constants";
import { useSnackbarPush } from "../SnackbarContext/SnackbarContext";

type PropTypes = {
  isOpen: boolean
  onClose: () => void
}

export const ImportWodifyDialog = ({ isOpen, onClose }: PropTypes) => {
  const { pushSuccess, pushError } = useSnackbarPush()
  const [selectedFile, setFile] = useState(null);
  const navigate = useNavigate()
  // const [, uploadCSV] = useMutation(uploadCsvMutation)

  const handleFileChange = useCallback((e: any) => {
    setFile(e.target.files[0]);
  }, [])

  const handleSubmit = useCallback(async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!selectedFile) {
      alert("Bitte wähle eine CSV-Datei aus");
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch(IMPORT_ENDPOINT, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      });

      if (response.ok) {
        pushSuccess('Import erfolgreich')
        onClose();
        navigate('/exercises')
      } else {
        pushError('Import fehlgeschlagen')
      }
    } catch (error) {
    }
  }, [navigate, onClose, pushError, pushSuccess, selectedFile])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[80]" onClose={onClose}>
        {/* Hintergrund Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur" />
        </Transition.Child>

        {/* Dialog-Fenster */}
        <div className="fixed inset-0 flex items-end justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="translate-y-full opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="translate-y-full opacity-0"
          >
            <div className="fixed inset-0 w-screen overflow-y-auto p-4">
              <div className="flex min-h-full items-center justify-center">
                <Dialog.Panel className="bg-neutral-100/5 backdrop-blur-2xl rounded-lg shadow-lg p-5 max-w-md w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-white pb-2"
                  >
                    Daten aus Wodify importieren
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-neutral-500">
                      Um die Daten aus Wodify zu importieren, musst du hier deine zuvor exportierte CSV-Datei hochladen.
                    </p>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="grid mt-2 text-white text-center">
                        <input type="file" accept=".xlsx" onChange={handleFileChange} />
                    </div>

                    <div className="grid grid-cols-5 gap-4 mt-4">
                      <button
                        type="button"
                        className="flex justify-center items-center gap-2 col-span-3 px-4 py-2 bg-red-500 text-white rounded-md"
                        onClick={onClose}
                      >
                        <XMarkIcon className={'w-5 h-5'}/>
                        <span>Abbrechen</span>
                      </button>

                      <button
                        type="button"
                        className="flex justify-center items-center col-span-2 px-4 py-2 bg-lime-500 text-white rounded-md"
                        onClick={handleSubmit}
                      >
                        <CheckIcon className={'w-5 h-5'}/>
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
)
}
