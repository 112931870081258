import { offlineExchange } from '@urql/exchange-graphcache'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'
import { readOneExerciseQuery, readWorkoutsByDateQuery } from '../queries'

export const cacheDatabaseName = 'track-the-rep-cache'

const storage = makeDefaultStorage({
  idbName: cacheDatabaseName,
  maxAge: 30,
});

const cache = offlineExchange({
  schema: JSON.parse(window.localStorage.getItem('schema')),
  keys: {},
  resolvers: {
    Query: {
      readOneWorkout: (parent, args, cache) => ({
        __typename: 'Workout',
        id: args.input.id,
      }),
      readOneExercise: (parent, args, cache) => ({
        __typename: 'Exercise',
        id: args.input.id,
      }),
    }
  },
  optimistic: {
    addResult: (variables, cache, info) => {
      return {
        __typename: 'ExerciseResult',
        id: 'unknown',
        created: new Date().toLocaleString('sv'),
        repetitions: variables.input.repetitions,
        result: variables.input.weight,
        notice: null
      }
    },
  },
  updates: {
    Mutation: {
      addResult(result, args, cache, _info) {
        cache.updateQuery({ query: readOneExerciseQuery, variables: { input: { id: args.input.exerciseID }} }, data => {
          if (args.input.exerciseResultID) {
            return data
          }

          return {
            readOneExercise: {
              ...data.readOneExercise,
              exerciseResults: [
                result.addResult,
                ...(data.readOneExercise?.exerciseResults || []),
              ]
            }
          }
        });
      },
      addWorkout(result, args, cache, _info) {
        cache.updateQuery({ query: readWorkoutsByDateQuery, variables: { input: { date: args.input.date }} }, data => {
          return {
            readWorkoutsByDate: {
              ...result.addWorkout
            }
          }
        });
      },
      deleteWorkout(result, args, cache, _info) {
        cache.updateQuery({ query: readWorkoutsByDateQuery, variables: { input: { date: args.input.date }} }, data => {
          return {
            readWorkoutsByDate: {
              ...result.deleteWorkout
            }
          }
        });
      },
    }
  },
  storage,
  isOfflineError: (error, result) => {
    return error &&
      (error?.message === '[Network] Load failed'
        || (
          error.networkError &&
          !error.response &&
          ((typeof navigator !== 'undefined' && navigator.onLine === false) ||
            /request failed|failed to fetch|network\s?error/i.test(
              error.networkError.message
            ))
        ))
  }
});

export default cache;
