import { graphql } from "../../../gql";

export const registerMemberMutation = graphql(`
  mutation RegisterMember($input: RegisterMemberInput!) {
    registerMember(input: $input)
  }
`);

export const setNewPasswordMutation = graphql(`
  mutation SetNewPassword($input: SetNewPasswordInput!) {
    setNewPassword(input: $input) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`);

export const loginUserMutation = graphql(`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`);

export const refreshTokenMutation = graphql(`
  mutation RefreshToken($refresh_token: String!) {
    refreshToken(refresh_token: $refresh_token) {
      id
      access_token
      refresh_token
      expiry
    }
  }
`)

export const logoutUserMutation = graphql(`
  mutation LogoutUser {
    logoutUser
  }
`);

export const addResultMutation = graphql(`
  mutation AddResult($input: AddResultInput!) {
    addResult(input: $input) {
      id
      created
      repetitions
      result
      notice
    }
  }
`);

export const addResultForPresetMutation = graphql(`
  mutation AddResultForPreset($input: AddResultForPresetInput!) {
    addResultForPreset(input: $input) {
      id
      results {
        id
        result
        athletID
      }
    }
  }
`);

export const addAthletMutation = graphql(`
  mutation AddAthlet($input: AddAthletInput!) {
    addAthlet(input: $input) {
      id
      teammates {
        id
        name
      }
    }
  }
`);

export const createCheckoutSessionMutation = graphql(`
  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(input: $input)
  }
`);

export const createBillingPortalSessionMutation = graphql(`
  mutation CreateBillingPortalSession {
    createBillingPortalSession
  }
`);

export const updateSubscriptionStatusMutation = graphql(`
  mutation UpdateSubscriptionStatus {
    updateSubscriptionStatus {
      id
      hasActiveSubscription
      hasBoxMembership
      hasPendingBoxMembership
    }
  }
`);

export const joinBoxMutation = graphql(`
  mutation JoinBox($input: JoinBoxInput!) {
    joinBox(input: $input) {
      id
      hasActiveSubscription
      hasBoxMembership
      hasPendingBoxMembership
    }
  }
`);

export const confirmBoxMembershipMutation = graphql(`
  mutation ConfirmBoxMembership($input: ConfirmBoxMembershipInput!) {
    confirmBoxMembership(input: $input) {
      id
      title
      confirmedMembers {
        id
        firstName
        surname
      }
      requestedMembers {
        id
        firstName
        surname
      }
    }
  }
`);

export const cancelBoxMembershipMutation = graphql(`
  mutation CancelBoxMembership($input: CancelBoxMembershipInput!) {
    cancelBoxMembership(input: $input) {
      id
      title
      confirmedMembers {
        id
        firstName
        surname
      }
      requestedMembers {
        id
        firstName
        surname
      }
    }
  }
`);

export const addWorkoutMutation = graphql(`
  mutation AddWorkout($input: AddWorkoutInput!) {
    addWorkout(input: $input) {
      public {
        id
        title
        date
        customer {
          id
          title
        }
        presets {
          id
          sort
          repetitions
        }
      }
      private {
        id
        title
        date
        presets {
          id
          repetitions
        }
      }
    }
  }
`);

export const deleteWorkoutMutation = graphql(`
  mutation DeleteWorkout($input: DeleteWorkoutInput!) {
    deleteWorkout(input: $input) {
      public {
        id
      }
      private {
        id
      }
    }
  }
`);

export const resetPasswordMutation = graphql(`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`);

